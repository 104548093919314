import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    classes  : [{}]
}

export const classSlice = createSlice({
  name: '@@classes',
  initialState,   
  reducers: {
    setClasses: (state , action : PayloadAction<any>) => {
      state.classes = action.payload
    }
  }
})

export const { setClasses } = classSlice.actions

export const classReducer =  classSlice.reducer