import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    value : {show : false , title : ""},
}

export const sidebarSlice = createSlice({
  name: '@@showSidebar',
  initialState,   
  reducers: {
    setSidebar: (state , action : PayloadAction<any>) => {
        console.log(state.value , "dipatch action value");
        console.log(action.payload , "dispatch payload value");
        state.value.show = action.payload.show;
        state.value.title = action.payload.title;
    }
  }
})

export const { setSidebar } = sidebarSlice.actions

export const sidebarReducer =  sidebarSlice.reducer