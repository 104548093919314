import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    value : 0
}

export const currentTutorialIndex = createSlice({
  name: '@@tutorialIndex',
  initialState,   
  reducers: {
    setCurrentTutorialIndex: (state , action : PayloadAction<any>) => {
        state.value = action.payload
    }
  }
})

export const { setCurrentTutorialIndex } = currentTutorialIndex.actions

export const currentTutorialIndexReducer =  currentTutorialIndex.reducer
