import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    value : {}
}

export const currentTutorialSlice = createSlice({
  name: '@@tutorial',
  initialState,   
  reducers: {
    setTutorial: (state , action : PayloadAction<any>) => {
        state.value = action.payload
    }
  }
})

export const { setTutorial } = currentTutorialSlice.actions

export const currentTutorialReducer =  currentTutorialSlice.reducer
