import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    userpref : {}
}

export const userPrefSlice = createSlice({
  name: '@@userpref',
  initialState,   
  reducers: {
    setUserPref: (state , action : PayloadAction<any>) => {
      state.userpref = action.payload
    }
  }
})

export const { setUserPref } = userPrefSlice.actions

export const userPrefReducer =  userPrefSlice.reducer