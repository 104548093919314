import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    value : {}
}

export const authSlice = createSlice({
  name: '@@auth',
  initialState,   
  reducers: {
    setUser: (state , action : PayloadAction<any>) => {
      state.value = action.payload
    }
  }
})

export const { setUser } = authSlice.actions

export const authReducer =  authSlice.reducer