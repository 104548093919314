import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    value : {}
}

export const articleSlice = createSlice({
  name: '@@article',
  initialState,   
  reducers: {
    setCurrentArticle: (state , action : PayloadAction<any>) => {
      state.value = action.payload
    }
  }
})

export const { setCurrentArticle } = articleSlice.actions

export const articleReducer =  articleSlice.reducer