import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { blogReducer } from './blog';
import { layoutReducer } from './layout';
import {authReducer} from "./authops"
import {jobReducer} from "./jobs";
import { eventReducer } from './events';
import { classReducer } from './classes';
import { userPrefReducer } from './userpref';
import { resumeReducer } from './resume';
import { articleReducer } from './currentArticle';
import { themeReducer } from './theme';
import { sidebarReducer } from './sidebar';
import { tutorialReducer } from './tutorials';
import { currentTutorialReducer } from './currentTutorial';
import { currentTutorialIndexReducer } from './currentTutorialIndex';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['layout', 'blog' , 'auth' , 'jobs' , 'events' , 'classes' , 'userpref' , 'resume' , 'showSidebar' , "currentTutorialReducer" , "currentTutorialIndexReducer"],
};

const reducer = combineReducers({
  auth : authReducer,
  layout: layoutReducer,
  jobs: jobReducer,
  blog: blogReducer,
  events : eventReducer,
  classes : classReducer,
  userpref: userPrefReducer,
  resume: resumeReducer,
  article: articleReducer,
  theme: themeReducer,
  showSidebar : sidebarReducer,
  tutorial : tutorialReducer,
  currentTutorialReducer : currentTutorialReducer,
  currentTutorialIndexReducer : currentTutorialIndexReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // .prepend(routerMiddleware),
  //   .concat(usersApi.middleware),
  devTools: true,
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
// top-level state
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
