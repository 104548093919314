import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Popover,
  Slider,
  Button,
  MenuItem,
  Select,
} from '@mui/material';
import Image from 'next/image';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import AddIcon from '@mui/icons-material/Add';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Streak from '/public/assets/icons/music.svg';
import Disc1 from '/public/assets/icons/disc1.png';
import Disc2 from '/public/assets/icons/disc2.png';
import Disc3 from '/public/assets/icons/disc3.png';
import VinylImage from '/public/assets/musicplayer.png';
import { useMusicTimer } from './MusicTimerContext';

const tracks = {
  'Nature’s Symphony': '/assets/music/music1-1.mp3',
  'Soothing Waves': '/assets/music/music-w.mp3',
  'Chirping Paradise': '/assets/music/music-bird.mp3',
};

const MusicTimer = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTrack, setSelectedTrack] = useState('Nature’s Symphony');
  const [audio, setAudio] = useState(null);
  const {
    volume,
    setVolume,
    isPlaying,
    setIsPlaying,
    time,
    handleAddMinute,
    resetTimer,
  } = useMusicTimer();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const handleTrackChange = (event) => {
    setSelectedTrack(event.target.value);
    setIsPlaying(false); // Pause current audio before switching
  };

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
    }

    const newAudio = new Audio(tracks[selectedTrack]);
    newAudio.volume = volume / 100;
    setAudio(newAudio);

    if (isPlaying) {
      newAudio.play();
    }

    newAudio.onended = () => setIsPlaying(false);

    return () => {
      newAudio.pause();
      newAudio.currentTime = 0;
    };
  }, [selectedTrack]);

  useEffect(() => {
    if (audio) {
      if (isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [isPlaying, audio]);

  useEffect(() => {
    if (audio) {
      audio.volume = volume / 100;
    }
  }, [volume, audio]);

  return (
    <>
      <Box
        onClick={handleClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #DADADA',
          borderRadius: '12px',
          backgroundColor: isPlaying ? '#D8E4FF' : '#F9FAFB',
          width: 'fit-content',
          padding: '4px 8px',
          cursor: 'pointer',
          marginLeft: '-40px',
          marginRight: '-30px',
        }}
      >
        <Box sx={{ width: 18, height: 18, position: 'relative', mr: 1 }}>
          <Image src={Streak} alt="Timer Icon" layout="fill" objectFit="contain" />
        </Box>
        <Typography sx={{ fontSize: '1rem', fontWeight: 500, color: '#4A4A4A' }}>
          {formatTime(time)}
        </Typography>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiPopover-paper': {
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Box
          sx={{
            width: 250,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            borderRadius: '10px',
          }}
        >
          <Typography variant="subtitle1" fontWeight="bold" mb={1}>
            Timer and Music
          </Typography>
          <Box display="flex" alignItems="center" width="100%" mb={2}>
            <VolumeUpIcon />
            <Slider
              value={volume}
              onChange={(e, newValue) => setVolume(newValue)}
              aria-labelledby="volume-slider"
              sx={{ color: '#9747FF', marginLeft: 1, flexGrow: 1 }}
            />
          </Box>
          <Box
            sx={{
              fontSize: '2rem',
              fontWeight: 'bold',
              color: '#4A4A4A',
              border: '1px solid #E0E0E0',
              borderRadius: '10px',
              padding: '8px 16px',
              marginBottom: 2,
              width: '100%',
              textAlign: 'center',
              backgroundColor: '#F9FAFB',
            }}
          >
            {formatTime(time)}
          </Box>
          <Box display="flex" justifyContent="space-around" width="100%">
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddMinute}
              sx={{
                textTransform: 'none',
                fontSize: '0.875rem',
                color: '#575757',
                borderRadius: '10px',
                borderColor: '#DADADA',
              }}
            >
              1 min
            </Button>
            <IconButton
              sx={{
                color: '#4A4A4A',
                border: '1px solid #E0E0E0',
                borderRadius: '50%',
                '&:hover': { borderColor: '#9747FF' },
              }}
              onClick={resetTimer}
            >
              <StopIcon />
            </IconButton>
            <IconButton
              sx={{
                backgroundColor: '#9747FF',
                color: '#fff',
                borderRadius: '50%',
                '&:hover': { backgroundColor: '#8E24AA' },
              }}
              onClick={handlePlayPause}
            >
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
          </Box>

   {/* Vinyl Player Section */}
<Box
  sx={{
    marginTop: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  }}
>
  <Box
    sx={{
      width: '100%',
      height: 'auto',
      position: 'relative',
      borderRadius: '10px',
      overflow: 'hidden',
    }}
  >
    {/* Vinyl Image */}
    <Image src={VinylImage} alt="Vinyl Player" layout="responsive" objectFit="cover" />

    {/* Disc Image Overlay Wrapper */}
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '21%',
        transform: 'translate(-50%, -50%)', // Centering transform remains in this wrapper
        width: 38,
        height: 38,
      }}
    >
      {/* Rotating Disc */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          animation: isPlaying ? 'rotateDisc 5s linear infinite' : 'none', // Rotation only in inner wrapper
        }}
      >
        <Image
          src={
            selectedTrack === 'Nature’s Symphony'
              ? Disc1
              : selectedTrack === 'Soothing Waves'
              ? Disc2
              : Disc3
          }
          alt="Disc Overlay"
          layout="responsive"
          objectFit="contain"
        />
      </Box>
    </Box>
  </Box>

  <Box display="flex" alignItems="center" width="100%" mt={2}>
    <Select
      value={selectedTrack}
      onChange={handleTrackChange}
      sx={{
        flexGrow: 1,
        borderRadius: '8px',
        backgroundColor: '#F9FAFB',
        border: '1px solid #DADADA',
        height: 40,
      }}
    >
      {Object.keys(tracks).map((track) => (
        <MenuItem key={track} value={track}>
          {track}
        </MenuItem>
      ))}
    </Select>
  </Box>
</Box>

{/* Add the keyframes animation globally */}
<style jsx global>{`
  @keyframes rotateDisc {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`}</style>




        </Box>
      </Popover>
    </>
  );
};

export default MusicTimer;
