import { useAppSelector } from '@msanvarov/store';
import Link from 'next/link';

export const Footer = () => {

  const value = useAppSelector(state => state.theme.value)
  return (
    <footer className="footer-area noPrint">
      <div className="container">
        <div className="footer-content text-center">
          <Link href="/" className="logo">
            <img src="/assets/logo/logo.png" alt="Logo" />
          </Link>
          <ul className="footer-menu">
            <li>
              <Link href="/">Home</Link>
            </li>
            <li>
              <Link href="/bio">About</Link>
            </li>
            {/* <li>
              <Link href="/portfolio">Products</Link>
            </li> */}
            <li>
              <Link href="/terms-of-service">Terms&Services</Link>
            </li>
            <li>
              <Link href="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link href="/refund-policy">Refund Policy</Link>
            </li>
            <li>
              <Link href="/contact">Contact</Link>
            </li>
          </ul>
          <p className="copyright">

            ©Sponsorgenix 2024
          </p>
        </div>
      </div>
    </footer>
  );
};
