import { UserButton, useUser } from "@clerk/nextjs";

const UserButtonComponent = () => {
    

    const {isSignedIn} = useUser();

    if(isSignedIn){
        return <UserButton />
    }else{
        return null 
    }

}

export default UserButtonComponent;