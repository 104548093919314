import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    job  : [{}]
}

export const jobSlice = createSlice({
  name: '@@jobs',
  initialState,   
  reducers: {
    setJobs: (state , action : PayloadAction<any>) => {
      state.job = action.payload
    }
  }
})

export const { setJobs } = jobSlice.actions

export const jobReducer =  jobSlice.reducer