

import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    value : []
}

export const tutorialSlice = createSlice({
  name: '@@tutorials',
  initialState,   
  reducers: {
    setTutorials : (state , action : PayloadAction<any>) => {
        state.value = action.payload
    }
  }
})

export const { setTutorials } = tutorialSlice.actions

export const tutorialReducer =  tutorialSlice.reducer