import React, { createContext, useContext, useState, useEffect, useRef } from 'react';

const MusicTimerContext = createContext();

export const MusicTimerProvider = ({ children }) => {
  const [volume, setVolume] = useState(50);
  const [isPlaying, setIsPlaying] = useState(false);
  const [time, setTime] = useState(180);
  const audioRef = useRef(null);

  // Initialize audio element
  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio('/assets/music/music1.mp3');
    }
    return () => {
      if (audioRef.current) audioRef.current.pause(); // Clean up when unmounting
    };
  }, []);

  // Control audio play/pause
  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      audioRef.current.volume = volume / 100;
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, volume]);

  // Timer countdown
  useEffect(() => {
    let timerInterval;
    if (isPlaying && time > 0) {
      timerInterval = setInterval(() => setTime((prev) => prev - 1), 1000);
    } else if (time === 0) {
      setIsPlaying(false);
    }
    return () => clearInterval(timerInterval);
  }, [isPlaying, time]);

  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = newVolume / 100;
    }
  };

  const handlePlayPause = () => setIsPlaying((prev) => !prev);

  const handleAddMinute = () => setTime((prevTime) => prevTime + 60);

  const resetTimer = () => setTime(0);

  return (
    <MusicTimerContext.Provider
      value={{
        volume,
        setVolume: handleVolumeChange,
        isPlaying,
        setIsPlaying: handlePlayPause,
        time,
        setTime,
        handleAddMinute,
        resetTimer,
      }}
    >
      {children}
    </MusicTimerContext.Provider>
  );
};

export const useMusicTimer = () => useContext(MusicTimerContext);
