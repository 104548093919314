import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    value : {
      id : "",
      name_of_resume : "",
      resume_data : {
          name : "",
          jobTitle : "",
          email : "",
          phone : "",
          links : ["" , ""],
          education : [{}],
          company : [{}],
          projects : [{}],
          skills : [{}],
          achievements : [],
          socialEngagement : [{}],
      }
    }
}

export const resumeSlice = createSlice({
  name: '@@resume',
  initialState,   
  reducers: {
    setResume: (state , action : PayloadAction<any>) => {

      console.log(state.value , "dipatch action value");
      console.log(action.payload , "dispatch payload value");
      state.value.resume_data = action.payload.resume_data
    },
    setWholeResume : (state , action :PayloadAction<any>) => {
      console.log(state.value , "dipatch action value");
      console.log(action.payload , "dispatch payload value");
      state.value = action.payload.value
    
    }
  }
})

export const { setResume , setWholeResume } = resumeSlice.actions

export const resumeReducer =  resumeSlice.reducer