import React, { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Home, Work, School, Article, Event } from '@mui/icons-material';
import { useRouter } from 'next/router';
import Paper from '@mui/material/Paper';
import { useTheme, useMediaQuery } from '@mui/material';

export const MobileBottomNav = () => {
  const [value, setValue] = useState(0);
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleNavigation = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        router.push('/');
        break;
      case 1:
        router.push('/resume');
        break;
      case 2:
        router.push('/jobs');
        break;
      case 3:
        router.push('/classes');
        break;
      case 4:
        router.push('/events');
        break;
      default:
        router.push('/');
    }
  };

  if (!isMobile) return null;

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
      }}
      elevation={3}
    >
      <BottomNavigation
        value={value}
        onChange={handleNavigation}
        showLabels
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <BottomNavigationAction
          label="Home"
          icon={<Home />}
          selected={value === 0}
          sx={{
            '&.Mui-selected': {
              color: theme.palette.primary.main,
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
              },
            },
          }}
        />
        <BottomNavigationAction
          label="Resume"
          icon={<Article />}
          selected={value === 1}
          sx={{
            '&.Mui-selected': {
              color: theme.palette.primary.main,
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
              },
            },
          }}
        />
        <BottomNavigationAction
          label="Jobs"
          icon={<Work />}
          selected={value === 2}
          sx={{
            '&.Mui-selected': {
              color: theme.palette.primary.main,
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
              },
            },
          }}
        />
        <BottomNavigationAction
          label="Classes"
          icon={<School />}
          selected={value === 3}
          sx={{
            '&.Mui-selected': {
              color: theme.palette.primary.main,
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
              },
            },
          }}
        />
        <BottomNavigationAction
          label="Events"
          icon={<Event />}
          selected={value === 4}
          sx={{
            '&.Mui-selected': {
              color: theme.palette.primary.main,
              '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
              },
            },
          }}
        />
      </BottomNavigation>
    </Paper>
  );
};
