import {
  setUser,
  toggleDisplayMobileNavbar,
  useAppDispatch,
  useAppSelector,
} from '@msanvarov/store';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { SetStateAction, useState, useEffect } from 'react';
import { menu } from './menu';
import UserButtonComponent from '../../../../../apps/personal-portfolio/components/UserButtonComponent';
import { useUser } from '@clerk/nextjs';

import HomeIcon from '@mui/icons-material/Home';
import MusicTimer from '../layout/MusicTimer.jsx';
import ArticleIcon from '@mui/icons-material/Article';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import SearchIcon from '@mui/icons-material/Search';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  TextField,
  InputAdornment,
  IconButton,
  Drawer,
  Grid,
  Typography,
  Paper,
  List,
  ListItemText,
  ListItem,
  Box,
  Tooltip,
  Menu,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StoreIcon from '@mui/icons-material/Store';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Streak from '../../../../../apps/personal-portfolio/public/assets/icons/streak.svg';
import Image from 'next/image';
import { getStreak } from '../../../../../apps/personal-portfolio/utils/dataFetch';

export const Header = ({
  setShowSignup,
  isSidebarCollapsed, // Accept isSidebarCollapsed prop
}: {
  setShowSignup?: React.Dispatch<SetStateAction<boolean>>;
  isSidebarCollapsed?: boolean;
}) => {
  const router = useRouter();
  const isHomePage = router.pathname === '/';
  const [isMobile, setIsMobile] = useState(false);
  // Determine logo src based on conditions
  const logoSrc = isHomePage
    ? isSidebarCollapsed
      ? '/assets/logo/logo-empty.png'
      : '/assets/logo/logo.svg'
    : '/assets/logo/logo.svg'; // Default logo for non-home pages

  const dispatch = useAppDispatch();
  const pathname = router.asPath;

  const { displayMobileNavbar } = useAppSelector((state) => state.layout);
  const { isLoaded, isSignedIn, user } = useUser();
  const { value } = useAppSelector((state) => state.auth);

  console.log('this is the value of user : ', value);
  // Search input state
  const [searchQuery, setSearchQuery] = useState('');

  // Avatar menu states
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  // Drawer state for mobile menu
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  // Dynamic styles for mobile view
  const [headerStyles, setHeaderStyles] = useState({
    height: '60px',
    width: 'calc(100% - 80px)',
    margin: '0 40px',
    transition: 'none',
  });

  // Header visibility state
  const [isHeaderVisible, setHeaderVisible] = useState(true);

  // Track scroll position
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [streak_count, setStreakCount] = useState(1);
  useEffect(() => {
    if (user) {
      setIsLoading(true); // Start loading when the API call is made
      getStreak(user?.emailAddresses[0].emailAddress)
        .then((data) => {
          // Ensure the streak count exists
          const streak = data?.data?.view_history[0]?.streak_count || 0;
          setStreakCount(streak); // Update streak count
          setIsLoading(false); // Set loading to false once data is received
        })
        .catch((error) => {
          console.error('Error fetching streak data:', error);
          setIsLoading(false); // Set loading to false in case of error
        });
    }
  }, [user]);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        // Mobile view
        setHeaderStyles({
          height: '50px',
          width: 'calc(100% - 20px)',
          margin: '0 10px',
          transition: 'transform 0.4s ease, opacity 0.4s ease',
        });
      } else {
        // Desktop view
        setHeaderStyles({
          height: '60px',
          width: 'calc(100% - 80px)',
          margin: '0 40px',
          transition: 'none', // No transition effect for desktop view
        });
      }
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        // Scrolling down
        setHeaderVisible(false);
      } else {
        // Scrolling up
        setHeaderVisible(true);
      }
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop); // For Mobile Safari
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isOnPath = (path: string) => pathname === path;

  const handleToggle = () => {
    dispatch(toggleDisplayMobileNavbar());
  };

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [isLoaded]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    // Set initial value
    handleResize();

    // Add event listener for resizing
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menu = [
    { path: '/', label: 'Home' },
    { path: '/resume', label: 'Resume' },
    { path: '/jobs', label: 'Jobs' },
    { path: '/classes', label: 'Classes' },
    { path: '/articles', label: 'Articles' },
    { path: '/events', label: 'Events' },
    { path: '/community', label: 'Community' },
    { path: '/dashboard', label: 'Dashboard' },
  ];
  const [filteredMenu, setFilteredMenu] = useState(menu);
  const searchOptions = [
    'Option 1',
    'Option 2',
    'Option 3',
    'Option 4',
    'Option 5',
  ];
  const [searchInput, setSearchInput] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(searchOptions);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchInput(value);

    // Filter menu items based on the search input
    const filtered = menu.filter((item) =>
      item.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredMenu(filtered);

    // Show dropdown if there are matches or if the input is empty
    setDropdownVisible(filtered.length > 0 || value === '');
  };

  const handleSearch = (option: string) => {
    const selectedItem = menu.find((item) => item.label === option);
    if (selectedItem) {
      router.push(selectedItem.path);
    }
    setDropdownVisible(false); // Close the dropdown
  };

  const handleFocus = () => {
    setDropdownVisible(true);
  };

  const handleBlur = () => {
    // Close the dropdown after a slight delay to allow click events to register
    setTimeout(() => {
      setDropdownVisible(false);
    }, 200);
  };
  const [open, setOpen] = useState(false);

  // Handle the click event to show/hide the tooltip
  // @ts-ignore
  const handleTooltipOpen = (event) => {
    event.preventDefault();
    setOpen(!open);
  };
  // @ts-ignore
  const [storeAnchorEl, setStoreAnchorEl] = useState(null);
  const isStoreMenuOpen = Boolean(storeAnchorEl);

  // Open dropdown on hover
  // @ts-ignore
  const handleMouseEnter = (event) => {
    setStoreAnchorEl(event.currentTarget);
  };

  // Close dropdown immediately on mouse leave
  const handleMouseLeave = () => {
    setStoreAnchorEl(null);
  };

  // Open dropdown on icon click
  // @ts-ignore
  const handleStoreIconClick = (event) => {
    event.preventDefault(); // Prevent default behavior
    setStoreAnchorEl(event.currentTarget); // Open the dropdown
  };

  // Handle external link redirection
  // @ts-ignore
  const handleMenuItemClick = (url) => {
    setStoreAnchorEl(null); // Close the dropdown after click
    window.open(url, '_blank');
  };

  //

  return (
    <header
      className="header-area noPrint"
      style={{
        position: 'fixed',
        width: headerStyles.width,
        margin: headerStyles.margin,
        zIndex: 1000,
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        borderRadius: '65px',
        fontWeight: '600',
        display:
          window.innerWidth <= 767
            ? isHeaderVisible
              ? 'flex'
              : 'none'
            : 'flex',
        // Apply visibility logic only for mobile view
        transition: headerStyles.transition,
        transform:
          window.innerWidth <= 767 && !isHeaderVisible
            ? 'translateY(-100%)'
            : 'translateY(0)',
        opacity: window.innerWidth <= 767 && !isHeaderVisible ? '0' : '1',
        height: headerStyles.height,
        alignItems: 'center',
        padding: '0 20px',
      }}
    >
      <div className="container">
        <div className="gx-row d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <Link href="/" className="logo">
              <img
                src={
                  isMobile
                    ? '/assets/logo/logo-h.svg' // Always logo.png for mobile view
                    : router.pathname === '/' ||
                      router.pathname === '/roadmaps' ||
                      router.pathname === '/notes' ||
                      router.pathname === '/interviewquiz' ||
                      router.pathname === '/community' ||
                      router.pathname === '/goals' ||
                      router.pathname === '/dashboard' ||
                      router.pathname === '/events' ||
                      router.pathname === '/articles' ||
                      router.pathname === '/classes'
                    ? isSidebarCollapsed
                      ? '/assets/logo/logo-h.svg' // Sidebar collapsed on home page
                      : '/assets/logo/logo-empty.png' // Sidebar not collapsed on home page
                    : '/assets/logo/logo-h.svg' // For all other pages
                }
                alt="Logo"
                style={{ height: '50%' }}
              />
            </Link>
            {/* MUI Search Bar */}
            <TextField
              variant="outlined"
              placeholder="Search..."
              size="small"
              value={searchInput}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '50px',
                },
                '& .MuiOutlinedInput-input': {
                  padding: '8px 14px',
                },
              }}
            />
            {isDropdownVisible && (
              <Paper
                elevation={3}
                sx={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  zIndex: 1000,
                  width: 250, // Match the dropdown width with the search bar width
                  borderRadius: '8px',
                  overflow: 'hidden',
                  mt: 1,
                  marginLeft: '250px',
                  border: '1px solid rgba(255, 255, 255, 0.18)', // Light inner border for glass effect
                  backdropFilter: 'blur(75px)', // Adds the blur effect
                  backgroundColor: 'rgba(255, 255, 255, 0.95)', // Semi-transparent background
                }}
              >
                <List>
                  {filteredMenu.length > 0 ? (
                    filteredMenu.map((item, index) => (
                      <ListItem
                        button
                        key={index}
                        onMouseDown={() => handleSearch(item.label)}
                        sx={{
                          '&:hover': {
                            backgroundColor: '#f5f5f5',
                          },
                        }}
                      >
                        <ListItemText primary={item.label} />
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary="No options available" />
                    </ListItem>
                  )}
                </List>
              </Paper>
            )}
          </div>
          <IconButton
            sx={{ display: { xs: 'block', md: 'none' } }} // Show menu icon on mobile
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <nav
            className={classNames('navbar', { active: displayMobileNavbar })}
            // @ts-ignore
            style={{ display: { xs: 'none', md: 'flex' } }} // Hide navbar on mobile
          >
            <ul
              className="menu"
              style={{ marginLeft: '50px', marginRight: '-50px' }}
            >
              {menu.map((entry, i) => {
                const isActive = router.pathname === entry.path;
                // const IconComponent = entry.Icon;
                return (
                  <li
                    className={isActive ? 'active' : undefined}
                    key={i}
                    style={{ textAlign: 'center', margin: '-4px' }}
                  >
                    <button
                      onClick={() => {
                        router.push(entry.path);
                        setDrawerOpen(false); // Assuming setDrawerOpen is defined
                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        fontSize: '0.8rem',
                        color: isActive ? '#427BF1' : '#747474',
                      }}
                    >
                      {entry.label === 'Home' && (
                        <>
                          <HomeIcon
                            style={{
                              fontSize: '1.5rem',
                              color: isActive ? '#427BF1' : '#747474',
                            }}
                          />
                          <span>{entry.label}</span>
                        </>
                      )}
                      {entry.label === 'Resume' && (
                        <>
                          <ArticleIcon
                            style={{
                              fontSize: '1.5rem',
                              color: isActive ? '#427BF1' : '#747474',
                            }}
                          />
                          <span>{entry.label}</span>
                        </>
                      )}
                      {entry.label === 'Jobs' && (
                        <>
                          <WorkIcon
                            style={{
                              fontSize: '1.5rem',
                              color: isActive ? '#427BF1' : '#747474',
                            }}
                          />
                          <span>{entry.label}</span>
                        </>
                      )}
                      {entry.label === 'Classes' && (
                        <>
                          <SchoolIcon
                            style={{
                              fontSize: '1.5rem',
                              color: isActive ? '#427BF1' : '#747474',
                            }}
                          />
                          <span>{entry.label}</span>
                        </>
                      )}
                      {entry.label === 'Articles' && (
                        <>
                          <ArticleIcon
                            style={{
                              fontSize: '1.5rem',
                              color: isActive ? '#427BF1' : '#747474',
                            }}
                          />
                          <span>{entry.label}</span>
                        </>
                      )}
                      {entry.label === 'Events' && (
                        <>
                          <EventIcon
                            style={{
                              fontSize: '1.5rem',
                              color: isActive ? '#427BF1' : '#747474',
                            }}
                          />
                          <span>{entry.label}</span>
                        </>
                      )}
                    </button>
                  </li>
                );
              })}
              <div
                onMouseEnter={handleMouseEnter} // Open dropdown on hover
                onMouseLeave={handleMouseLeave} // Close dropdown on mouse leave
                style={{ marginLeft: '-90px' }}
              >
                <IconButton
                  onClick={handleStoreIconClick} // Open dropdown on icon click
                  sx={{
                    fontSize: '1.5rem',
                    color: '#52C98E',
                  }}
                >
                  <StoreIcon />
                </IconButton>
                <Typography
                  sx={{
                    color: '#52C98E',
                    cursor: 'pointer',
                    marginTop: '-10px',
                  }}
                >
                  Store
                </Typography>

                {/* MUI Dropdown Menu */}
                <Menu
                  anchorEl={storeAnchorEl}
                  open={isStoreMenuOpen}
                  onClose={handleMouseLeave} // Close dropdown immediately when mouse leaves
                  disableScrollLock={true} // Allow page scrolling when dropdown is open
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    onMouseLeave: handleMouseLeave, // Immediate close when mouse leaves the menu
                  }}
                  sx={{
                    // backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    // backdropFilter: 'blur(10px)',
                    // boxShadow: 'none',
                    borderRadius: '20px',
                    border: '1px solid #d3d3d3', // Add grey border
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick(
                        'https://store.sponsorgenix.in/categories/e-books'
                      )
                    }
                  >
                    Notes & EBooks
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick(
                        'https://store.sponsorgenix.in/categories/consultation-guide'
                      )
                    }
                  >
                    Consultations
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick(
                        'https://store.sponsorgenix.in/categories/graphics-designing'
                      )
                    }
                  >
                    Services
                  </MenuItem>
                </Menu>
              </div>

              {Boolean(Object.keys(value).length) || Boolean(isSignedIn) ? (
                <UserButtonComponent />
              ) : (
                <li
                  className="applybtn"
                  style={{ cursor: 'pointer', textAlign: 'center' }}
                  // @ts-ignore
                  onClick={() => setShowSignup(true)}
                >
                  <IconButton>
                    <AccountCircleIcon />
                  </IconButton>
                  <div style={{ marginTop: '-8px', marginBottom: '8px' }}>
                    Signin
                  </div>
                </li>
              )}
              <Tooltip
                title="Do your daily task to refresh your streak!"
                open={open}
                onClose={() => setOpen(false)}
                disableHoverListener // Disabling the default hover behavior
                arrow
              >
                <Link
                  href="/dashboard"
                  passHref
                  style={{ textDecoration: 'none' }}
                >
                  <Box
                    onClick={handleTooltipOpen} // Handle click to toggle tooltip
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: '1px solid #E0E0E0',
                      borderRadius: '12px',
                      backgroundColor: '#F9FAFB',
                      width: 'fit-content',
                      marginLeft: '-30px',
                      padding: '4px 8px',
                      cursor: 'pointer', // Add a pointer cursor to indicate clickability
                    }}
                  >
                    {/* Icon */}
                    <Box
                      sx={{
                        width: 18,
                        height: 18,
                        position: 'relative',
                        mr: 1,
                      }}
                    >
                      <Image
                        src={Streak} // Replace with your image path
                        alt="Fire Icon"
                        layout="fill"
                        objectFit="contain"
                      />
                    </Box>

                    {isLoading ? (
                      <CircularProgress size={24} sx={{ color: '#4A4A4A' }} />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: '1rem',
                          fontWeight: 500,
                          color: '#4A4A4A',
                        }}
                      >
                        {streak_count}
                      </Typography>
                    )}
                  </Box>
                </Link>
              </Tooltip>

              <MusicTimer />
              <Link
                href="/dashboard"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontSize: '0.8rem',
                  color: '#427BF1',
                }}
              >
                <DashboardIcon
                  style={{ fontSize: '1.5rem', color: '#EDA950' }}
                />
                <span style={{ color: '#EDA950' }}>Dashboard</span>
              </Link>
            </ul>
            <a
              className="theme-btn"
              href="https://calendly.com/airedify"
              target="_blank"
              rel="noreferrer noopener"
            >
              ☕ Get 1v1 Class with AirEdify
            </a>
          </nav>
        </div>
      </div>

      {/* Mobile Drawer Menu */}
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: '250px',
            padding: '20px',
          },
        }}
      >
        <Grid container spacing={2}>
          {menu.map((entry, i) => {
            const isActive = isOnPath(entry.path);
            return (
              <Grid item xs={6} key={i}>
                <Link
                  href={entry.path}
                  onClick={() => {
                    // handleToggle();
                    setDrawerOpen(false);
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '0.8rem',
                    color: isActive ? '#427BF1' : '#747474',
                  }}
                >
                  {entry.label === 'Home' && (
                    <HomeIcon
                      style={{
                        fontSize: '2rem',
                        color: isActive ? '#427BF1' : '#747474',
                      }}
                    />
                  )}
                  {entry.label === 'Resume' && (
                    <ArticleIcon
                      style={{
                        fontSize: '2rem',
                        color: isActive ? '#427BF1' : '#747474',
                      }}
                    />
                  )}
                  {entry.label === 'Jobs' && (
                    <WorkIcon
                      style={{
                        fontSize: '2rem',
                        color: isActive ? '#427BF1' : '#747474',
                      }}
                    />
                  )}
                  {entry.label === 'Classes' && (
                    <SchoolIcon
                      style={{
                        fontSize: '2rem',
                        color: isActive ? '#427BF1' : '#747474',
                      }}
                    />
                  )}
                  {entry.label === 'Articles' && (
                    <ArticleIcon
                      style={{
                        fontSize: '2rem',
                        color: isActive ? '#427BF1' : '#747474',
                      }}
                    />
                  )}
                  {entry.label === 'Events' && (
                    <EventIcon
                      style={{
                        fontSize: '2rem',
                        color: isActive ? '#427BF1' : '#747474',
                      }}
                    />
                  )}
                  {entry.label === 'Community' && (
                    <WhatsAppIcon
                      style={{
                        fontSize: '2rem',
                        color: '#52C98E',
                      }}
                    />
                  )}
                  {entry.label === 'DashBoard' && (
                    <DashboardIcon
                      style={{
                        fontSize: '2rem',
                        color: '#EDA950',
                      }}
                    />
                  )}
                  {entry.label}
                </Link>
              </Grid>
            );
          })}

          {/* My Profile Section */}
          <Grid item xs={12}>
            <div
              style={{
                borderTop: '1px solid #ddd',
                paddingTop: '10px',
                marginTop: '10px',
                // textAlign: 'center',
                padding: '25px',
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginBottom: '10px',
                }}
              >
                My Profile
              </Typography>
              {Boolean(Object.keys(value).length) || Boolean(isSignedIn) ? (
                <UserButtonComponent />
              ) : (
                <div
                  className="applybtn"
                  style={{
                    cursor: 'pointer',
                    padding: '10px 0',
                  }}
                  onClick={() => {
                    // @ts-ignore
                    setShowSignup(true);
                    setDrawerOpen((state) => !state);
                  }}
                >
                  Apply
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Drawer>
    </header>
  );
};
