import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
    events  : [{}]
}

export const eventSlice = createSlice({
  name: '@@events',
  initialState,   
  reducers: {
    setEvents: (state , action : PayloadAction<any>) => {
      state.events = action.payload
    }
  }
})

export const { setEvents } = eventSlice.actions

export const eventReducer =  eventSlice.reducer