import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Assuming you're using MUI icons

const InfoBox = () => {
  const [visible, setVisible] = useState(true);

  // Effect to listen to scroll event and hide InfoBox on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) { // Hide after scrolling 50px
        setVisible(false);
      } else {
        setVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleCloseToast = () => {
    setVisible(false);
  };

  const handleStartClick = () => {
    // Add your functionality for the START button click
    console.log('Start button clicked');
  };

  return (
    visible && (
      <Box
        sx={{
          width: '100%',
          backgroundColor: '#427BF1', // Thin blue bar color
          color: '#fff', // Text color
          padding: '1px 16px', // Adjust height and padding
          display: { xs: 'none', md: 'flex' }, // Hide on mobile (xs and sm)
          justifyContent: 'space-between', // Space between Close icon and Start button
          alignItems: 'center', // Align vertically
          position: 'fixed', // Fixed at the bottom
          bottom: 0,
          left: 0,
          zIndex: 100000, // High z-index to ensure it stays on top
        }}
      >
        {/* Left Side: Close Icon and Text */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            size="small"
            aria-label="close"
            sx={{ marginRight: '8px', color: '#fff' }}
            onClick={handleCloseToast}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="caption">
            Chat with people with similar interests.
          </Typography>
        </Box>

        {/* Right Side: START Button */}
        <Button
          size="small"
          onClick={handleStartClick}
          sx={{
            backgroundColor: '#006DDD',
            color: 'white',
            fontWeight: '600',
            borderRadius: '20px',
            padding: '4px 12px',
            '&:hover': {
              backgroundColor: '#244981',
            },
          }}
        >
          START
        </Button>
      </Box>
    )
  );
};

export default InfoBox;
